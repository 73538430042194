















































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      title: ""
    };
  },
  methods: {
    setTitle(title: string) {
      this.title = title;
    }
  }
});
